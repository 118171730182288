import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/Layout"

const SpeakersPage = ({ data }) => {
  return (
    <Layout title="Speakers" withAnimation>
      <h2>Speakers</h2>
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id} className="speaker">
            <Link to={`/speaker/${node.slug}`}>
              <h3 className="speaker__name">{node.frontmatter.speaker}</h3>
            </Link>
            <span className="speaker__position">{node.frontmatter.position}</span>
           { node.frontmatter.company && (<span className="speaker__company"> - {node.frontmatter.company}</span>)}
          </article>
        ))
      }
    </Layout>
  )
}

export const query = graphql`
  query Speakers {
    allMdx(filter: {frontmatter: {type: {eq: "speaker"}}} sort: {fields: frontmatter___speaker, order: ASC}){
      nodes {
        frontmatter {
          speaker
          position
          company
        }
        id
        slug
      }
    }
    }
  `

export default SpeakersPage
