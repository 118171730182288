import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Header from './Header'
import Animation from './Animation'

const Layout = ({ title, children, page, withAnimation, hideSubnav }) => {
  return (
    <div className={withAnimation ? "site-with-animation" : "site"}>
      <Helmet>
        <title>{title} | Web3 Summit 2018</title>
      </Helmet>
      <Header hideSubnav={hideSubnav}></Header>
      <main className={page}>
        {children}
      </main>
      {withAnimation && <Animation />}
      <footer>
        <Link to="/imprint/">Imprint</Link>
      </footer>
    </div>
  )
}

export default Layout
