import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/Layout"
import NavBar from "../../../components/NavBar"
import SiteNavigation from "../../../paths.json"

const ProgramPageHacker = ({ data }) => {
  return (
    <Layout page={"hackerspace"} title="Studio 2.0 - Hackerspace" withAnimation hideSubnav>
      <NavBar subNav paths={SiteNavigation.paths[1].pages} />
      <article>
        <section className="hackerspace__context">
          <p>The
            {" "}
            <a className="hackerspace__link" href="https://medium.com/web3foundation/hackerspace-your-own-space-at-web3-summit-5dc1a9cb3e9f">Hackerspace</a>
            {" "}
            will be the center of ecosystem collaboration and community exchange at the Web3 Summit.
          </p>
          <p>
            Find out more about the self-organized and participant-driven Hackerspace Nodes below and stop by at
            {" "}
            <a className="hackerspace__link" href="https://www.funkhaus-berlin.net/p/studio-2.html">Studio 2.0</a>
            {" "}
            at the Web3 Summit 2018!
          </p>
        </section>
        <section className="hackerspace__items">
          {
            data.allMdx.nodes.map((node, i) => (
              <article key={i}>
                <Link to={`/node/${node.slug}`}>
                  <h3>{node.frontmatter.title}</h3>
                </Link>
                <p>{node.frontmatter.hosts}</p>
              </article>
            ))
          }
        </section>
      </article>

    </Layout>
  )
}

export const query = graphql`
  query Hackerspace {
      allMdx(filter: {frontmatter: {type: {eq: "hackerspace"}}}, sort: {fields: frontmatter___title, order: ASC}) {
        nodes {
          frontmatter {
            hosts
            title
          }
          slug
        }
      }
    }
  `

export default ProgramPageHacker
