import * as React from "react"
import Layout from "../../components/Layout"
import Container from "../../components/Container"
import VideoColumn from "../../components/VideoColumn"

const VideoPage = () => {
  const categories = ["talk", "panel", "workshop"]
  return (
    <Layout title="Video">
      <Container>
        {
          categories.map(category => {
            return <VideoColumn category={category} />
          })
        }
      </Container>
    </Layout>
  )
}

export default VideoPage
