import * as React from "react"
import Layout from "../../components/Layout"
import SiteNavigation from "../../paths.json";
import NavBar from "../../components/NavBar";

const ImprintPage = () => {
  return (
    <Layout page={"imprint"} title="Imprint" withAnimation>
      <NavBar subNav paths={SiteNavigation.imprint} />
      <article>
        <h2>Information provided according to Sec. 5 German Telemedia Act (TMG):</h2>
        <address>
          <p>WTS Web 3.0 Technologies Services GmbH</p>
          <p>Glogauer Strasse 6</p>
          <p>10999 Berlin</p>
        </address>
        <article>
          <h3>Represented by the managing directors:</h3>
          <span>Mr. Tim Howard and Mr. Gabriel Beeby</span>
        </article>
        <article>
          <h3>Contact:</h3>
          <h4>Telephone: </h4>
          <span>+49 (0) 30 695 18100</span>
          <br />
          <h4>Email: </h4>
          <span>
            <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
          </span>
        </article>
        <article>
          <h3>Register entry:</h3>
          <p>Eintragung im Handelsregister.</p>
          <h4>Registergericht: </h4>
          <span>Amtsgericht Charlottenburg</span>
          <br />
          <h4>Registernummer: </h4>
          <span>HRB 189664 B</span>
        </article>
        <article>
          <h3>VAT:</h3>
          <p>VAT Id number according to Sec. 27 a German Value Added</p>
          <h4>Tax Act:</h4>
          <p>DE318608297</p>
        </article>
        <article>
          <h3>Dispute resolution</h3>
          <h4>The European Commission provides a platform for online dispute resolution (OS): </h4>
          <span>
            <a href="https://ec.europa.eu/consumers/odr.">https://ec.europa.eu/consumers/odr.</a>
          </span>
          <p>We do not take part in online dispute resolutions at consumer arbitration boards.</p>
        </article>
        <article>
          <p>
            Design by {" "}
            <a href="http://fertigdesign.com/"> fertig design GbR</a>
          </p>
          <p>
            Coded by {" "}
            <a href="http://romanprikryl.com/">romanprikryl.com</a>
          </p>
        </article>
      </article>
      <article>
        <h2>Impressum</h2>
        <h3>Angaben gemäß § 5 TMG:</h3>
        <address>
          <p>WTS Web 3.0 Technologies Services GmbH</p>
          <p>Glogauer Strasse 6</p>
          <p>10999 Berlin</p>
        </address>
        <article>
          <h3>Vertreten durch die Geschäftsführer:</h3>
          <span>Tim Howard und Gabriel Beeby</span>
        </article>
        <article>
          <h3>Kontakt:</h3>
          <h4>Telefon:</h4>
          <span>+49 (0) 30 695 18100</span>
          <h4>Email:</h4>
          <span>
            <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
          </span>
        </article>
        <article>
          <h3>Registereintrag:</h3>
          <p>Eintragung im Handelsregister.</p>
          <h4>Registergericht:</h4>
          <span>Amtsgericht Charlottenburg</span>
          <h4>Registernummer:</h4>
          <span>HRB 189664 B</span>
        </article>
        <article>
          <h3>Umsatzsteuer:</h3>
          <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a</p>
          <h4>Umsatzsteuergesetz:</h4>
          <p>DE318608297</p>
        </article>
        <article>
          <h3>Streitschlichtung:</h3>
          <h4>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</h4>
          <span>
            <a href="https://ec.europa.eu/consumers/odr.">https://ec.europa.eu/consumers/odr.</a>
          </span>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </article>
        <article>
          <p>
            <a href="http://fertigdesign.com/">Design by fertig design GbR</a>
          </p>
          <p>
            <a href="http://romanprikryl.com/"> Coded by romanprikryl.com</a>
          </p>
        </article>
      </article>
    </Layout>
  )
}

export default ImprintPage
