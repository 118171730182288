import * as React from "react"

const ProgramSpeaker = ({ speaker }) => {

  const { name, company } = speaker

  return (
    <article className="program__speaker">
      <p>{name}</p>
      <p>{company}</p>
    </article>
  )
}

export default ProgramSpeaker
