import * as React from "react"
import Layout from "../../components/Layout"

const FaqPage = () => {
  return (
    <Layout page={"faq"} title="FAQ" withAnimation>
      <h2>FAQ</h2>
      <article>
        <h3>Where is Web3 Summit?</h3>
        <p>The three days Web3 Summit are held at {" "}
          <a href="http://www.funkhaus-berlin.net/">
          {" "}Funkhaus Berlin.
          </a>
          The address is {" "}
          <a href="https://www.google.de/maps/place/Bezirk+Treptow-K%C3%B6penick,+Nalepastra%C3%9Fe+18,+12459+Berlin/@52.4808,13.4991013,17z/data=!3m1!4b1!4m5!3m4!1s0x47a84f2e8cf00c47:0xf633c62d5ce91db!8m2!3d52.4808!4d13.50129">
          {" "} Nalepastraße 18, 12459 Berlin.
          </a>
          You can access the venue with our by tram, car, bike and taxi. There is a direct tram connecting Funkhaus to Berlin’s Friedrichshain area. Look out for the M21 at Frankfurter Tor Station; just make sure it’s heading in the right direction!</p>
      </article>
      <article>
        <h3>Where to stay in Berlin?</h3>
        <p>Berlin has a very good public transportation system. Therefore, you should be able to connect easily to the direct tram line from any location in the eastern part of the city. With that said, here are two hotels that we recommend, offering both close proximity to Funkhaus, and easy access to the vibrant neighborhoods of Friedrichshain and Kreuzberg.</p>
        <address>
          <a href="https://michelbergerhotel.com/de/">
            <p>Michelberger Hotel</p>
          </a>
          <a href="https://www.nh-hotels.de/hotel/nhow-berlin?gmb">
            <p>Hotel nhow Berlin</p>
          </a>
        </address>
      </article>
      <article>
        <h3>Is there wheelchair access?</h3>
        <p>Nearly all areas are accessible via wheelchair, with the exception of the Sound Chamber. If you need wheelchair assistance, please ask at the Info Point next to Registration. If you have any questions about accessibility, please reach out to us on {" "}
          <a href="mailto:contact@web3summit.com">
          {" "}contact@web3summit.com.
          </a>
          </p>
      </article>
      <article>
        <h3>Will there be food and drinks at the event?</h3>
        <p>There will be a variety of local food retailers from whom you will be able to purchase food and drinks.</p>
      </article>
      <article>
        <h3>Is there a live stream of the conference that I can follow online?</h3>
        <p>Yes, there will be a livestream of all talks on the main stage in Studio 1, with a link available on our website. There will also be video recording taking place throughout other sites in the venue.</p>
      </article>
      <article>
        <h3>Will there be free Wifi at Funkhaus?</h3>
        <p>Of course.</p>
      </article>
      <article>
        <h3>How can I volunteer?</h3>
        <p>Volunteers at Web3 Summit will receive free entry to the conference, food and drinks on the day of the assignment and a Web3 Summit T-Shirt! The volunteer assignment is 8 hours and the application form can be found here. The deadline for application submission is 20th September. You can also join our volunteer Riot channel ‘Public: Web3 Summit: Volunteer’. Need more info? Email us on {" "}
          <a href="mailto: volunteer@web3summit.com">
          {" "} volunteer@web3summit.com.
          </a>
          Looking forward to having you on board!</p>
      </article>
      <article>
        <h3>What are the different tickets available?</h3>
        <p>One ticket fits all. The price for a 3-day regular ticket is EUR 600,-.</p>
        <p>If you think you might qualify for discount, definitely fill out the {" "}
          <a href="https://goo.gl/forms/iJKciDmW82b5Zu9p1">
          {" "}Discounted Ticket Application Form.
          </a>
          </p>
        <p>All prices include 19% VAT and additional handling costs. Our partner Pretix will provide you with an invoice for each purchase. Please follow our {" "}
          <a href="https://twitter.com/web3foundation">
          Twitter
          </a>
          {" "} for updates. Walk up/Box office prices on-site will be higher.</p>
      </article>
      <article>
        <h3>Are the tickets refundable?</h3>
        <p>You will have the option to refund your tickets during the 14 days after you complete your purchase.</p>
      </article>
      <article>
        <h3>Receipt for my purchase</h3>
        <p>Our partner, Pretix, will automatically issue an invoice upon purchase. Required data will be requested during the ordering process. If there are any issues associated with your receipt, please write to us on {" "}
          <a href="mailto:contact@web3summit.com">
          contact@web3summit.com.
          </a>
          </p>
      </article>
      <article>
        <h3>Sponsorship</h3>
        <p>Web3 Summit will have no sponsorships. As part of the no-sponsorship policy, we will not support any form of advertising, including logo placement or preferential provision of speaker slots. However, we would accept your generous contributions, which we would use to finance a certain set of tickets for participants who would otherwise not be able to afford the full ticket price. Eligibility for discounted tickets will be verified through an application process.</p>
        <p>With gratitude for your generosity in making a contribution, and in proportion to the quantity of tickets your contribution subsidizes, we may be able to offer free tickets to your company in order to attend Web3 Summit. If you or your organization is interested in making a contribution, please contact us on {" "}
          <a href="mailto:contact@web3summit.com">
          contact@web3summit.com.
          </a>
          </p>
      </article>
    </Layout>
  )
}

export default FaqPage
