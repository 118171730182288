import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import Gallery from "../../../components/Gallery"

const PhotoPageStudio1 = ({ data }) => {
  return (
    <Layout title="Studio 1">
      <Gallery photos={data} />
    </Layout>
  )
}

export default PhotoPageStudio1

export const query = graphql`
  query studio1 {
    allMdx(
      filter: {slug: {regex: "/studio-1/"}}
      sort: {fields: frontmatter___title, order: ASC}
      ){
      nodes {
        frontmatter {
          photos {
            childImageSharp {
              gatsbyImageData
            }
          }
          studio
          title
        }
      }
    }
  }
`
