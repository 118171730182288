import * as React from "react"
import Layout from "../../components/Layout"
import SiteNavigation from "../../paths.json"
import NavBar from "../../components/NavBar";


const TermsAndConditions = () => {
  return (
    <Layout page="terms-and-conditions" title="Terms & Conditions" withAnimation>
      <NavBar subNav paths={SiteNavigation.imprint} />
      <article>
        <header>
          <h2>General Terms & Conditions of</h2>
          <article>
            <h3>Web 3.0 Technologies Stiftung</h3>
            <address>
              <p>Reiffergässli 4</p>
              <p>6300 Zug</p>
              <p>Switzerland</p>
            </address>
          </article>
        </header>
        <section>
          <header>
            <h2>- in the following Web3 -</h2>
            <p>
              Web3, a foundation under Swiss law with registered office in Zug, hosts the Web3 Summit at Funkhaus
              Berlin, Germany (Web3 Summit), an event to promote exchange and cooperation in the technology sector.
              Web3 sells the tickets for the Web3 Summit on the basis of the following conditions.
            </p>
          </header>
          <ol>
            <li className="item">
              <article className="item__title">
                <span>1.</span> {" "}
                <h2>Scope</h2>
              </article>
              <ol>
                <li>
                  <p>1.1</p>
                  <p>Unless agreed otherwise in individual cases, these general terms and conditions apply to any and
                    all contracts between Web3 and its customers in relation to the purchase of tickets and the
                    attendance of the Web3 Summit.</p>
                </li>
                <li>
                  <p>1.2</p>
                  <p>
                    With the purchase of tickets via the website www.web3summit.com, the customer (Customer) accepts
                    the following General Terms and Conditions (GT&Cs).
                  </p>
                </li>
                <li>
                  <p>1.3</p>
                  <p>Only natural and legal persons with full legal capacity and of age are eligible for purchasing
                    tickets for the Web3 Summit.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>2.</span> {" "}
                <h2>Right to attend events</h2>
              </article>
              <p>By purchasing a ticket, the customer acquires the right towards Web3 to attend the Web3 Summit under
                the following conditions:</p>
              <ol>
                <li>
                  <p>2.1</p>
                  <p>The tickets are freely transferable up to two (2) days prior to the beginning of the Web3 Summit
                    (18 August 2019, 00:01).</p>
                </li>
                <li>
                  <p>2.2</p>
                  <p>Web3 may change the program of an event at any time, in particular with regard to the agenda, as
                    far as the change in program does not change the nature of the event as a whole.</p>
                </li>
                <li>
                  <p>2.3</p>
                  <p>Web3‘s liability towards the attendees of the Web3 Summit is limited by the provisions contained
                    in clause 5.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>3.</span> {" "}
                <h2>Conclusion of contract, download of tickets</h2>
              </article>
              <ol>
                <li>
                  <p>3.1</p>
                  <p>Tickets for the Web3 Summit are sold by Web3 on their website www.web3summit.com.</p>
                </li>
                <li>
                  <p>3.2</p>
                  <p>By ordering tickets for the Web3 Summit, the Customer instructs Web3 to handle the ticket
                    purchase.
                  </p>
                </li>
                <li>
                  <p>3.3</p>
                  <p>A binding offer for the conclusion of a contract with Web3 on the purchase of tickets is placed
                    by the Customer by clicking on the button “Buy” after completing the order information. After the
                    order has been placed, the Customer will receive an automatic order confirmation via e-mail. By
                    the allocation and transmission of such order confirmation including the order number by Web3 or
                    the services provider preTix, a contract between the Customer and Web3 on the purchase of the
                    ordered tickets is concluded. The confirmation e-mail contains a link through which the Customer
                    may access, download and save the ticket in PDF form.</p>
                </li>
                <li>
                  <p>3.4</p>
                  <p>In case of the loss of a ticket for the Web3 Summit and/or the confirmation e-mail, Customers may
                    enter the e-mail address used for the purchase on www.web3summit.com to once again receive the
                    confirmation e-mail including the link to access the ticket.</p>
                </li>
                <li>
                  <p>3.5</p>
                  <p>Purchased tickets are generally non-exchangeable and non-refundable. Consumer’s rights for
                    cancellation (clause 6) remain unaffected.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>4.</span> {" "}
                <h2>Prices and payment terms</h2>
              </article>
              <ol>
                <li>
                  <p>4.1</p>
                  <p>The ticket prices for the Web3 Summit are available on the event website www.web3summit.com. The
                    total price of the services ordered is shown to the Customer before the completion of the order.
                  </p>
                </li>
                <li>
                  <p>4.2</p>
                  <p>Unless agreed otherwise in individual cases, the price for Customers’ purchases is payable
                    immediately upon the conclusion of the contract.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>5.</span> {" "}
                <h2>Limitation of liability</h2>
              </article>
              <ol>
                <li>
                  <p>5.1</p>
                  <p>Web3, its legal representatives or vicarious agents are only liable for damages if the damage</p>
                  <br />
                  <p>(a) results from intentional acts or gross negligence; or </p>
                  <p>(b) result from the culpable breach of a fundamental contractual obligation (cardinal obligation),
                    i.e. an obligation, the fulfillment of which makes the proper execution of the contract possible
                    or the violation of which puts the achievement of the purpose of the contract at risk and on the
                    fulfilment of which the contractual partner therefore may regularly rely.</p>
                </li>
                <li>
                  <p>5.2</p>
                  <p>For the rest, any liability of Web3 for damages – irrespective of the underlying legal ground –
                    is excluded.</p>
                </li>
                <li>
                  <p>5.3</p>
                  <p>In case Web3 is liable for the violation of a fundamental contractual obligation in accordance
                    with clause 5.1. (b), without any occurrence of gross negligence or intent, their liability
                    towards merchants is limited to the extent of damage which was reasonably foreseeable for Web3 at
                    the time of entering into the contract due to the circumstances known at the time.</p>
                </li>
                <li>
                  <p>5.4</p>
                  <p>The aforementioned limitations or exclusions of liability shall not apply in case of culpable
                    injury to life, body and health, for liability claims under the German Product Liability Act
                    (Produkthaftungsgesetz) or in case of a contractually agreed obligation to assume liability
                    irrespective of any fault.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>6.</span> {" "}
                <h2>Cancellation right</h2>
              </article>
              <ol>
                <li>
                  <p>6.1</p>
                  <p>Consumers do not have a general right to cancel in the case of contracts for services in the area
                    of provision of accommodation other than for residential purposes, the transport of goods, car
                    rental services, catering, or further services relating to leisure activities, where the contract
                    provides for a specific date or period of performance (s. 312g (2) Sentence 1 No. 9 BGB (German
                    Civil Code)).</p>
                </li>
                <li>
                  <p>6.2</p>
                  <p>For all other contracts with consumers, the following shall apply:</p>
                  <h3>Information on cancellation rights
                    Right to cancel</h3>
                  <p>You have the right to cancel this contract within fourteen days, without giving any reason. The
                    14-day cancellation period begins on the day on which the goods are received by you, or by a third
                    person named by you, other than the carrier.</p>
                  <p>In order to exercise your right to cancel, you must notify us,</p>
                  <article className="item__contact">
                    <h3>WTS Web 3.0 Technologies Services GmbH,</h3>
                    <address>
                      <span>Glogauer Straße 6, 10999 Berlin, </span>
                      <span>Germany</span>
                    </address>
                    <h3>phone:</h3>
                    <span> +49 (0) 30 695 18100, </span>
                    <h3>email: </h3>
                    <span>
                      <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
                    </span>
                  </article>
                  <p>of your decision to cancel the contract by sending us a clear written statement to that effect
                    (e.g. in a postal letter, telefax message or eMail). You may use the sample cancellation form
                    attached hereto, but are under no obligation to do so.</p>
                    <br/>
                  <p>To comply within the cancellation period, it is sufficient to send your notification of
                    exercising your right to cancel before the cancellation period ends.</p>
                  <h3>Consequences of cancellation</h3>
                  <p>If you cancel this contract, we have to immediately refund to you any payments we have received
                    from you, including delivery charges (except for the additional costs resulting from your choosing
                    a different form of delivery than the standard, most favourable form offered by us), by no later
                    than 14 days after the day on which we receive your notification of cancellation of this contract.
                    For the refund, we will use the means of payment you used for the original transaction, unless
                    explicitly agreed otherwise with you; you will not be charged any fees for the refund.</p>
                  <h3>Sample cancellation form</h3>
                  <p>(If you wish to cancel the contract, please fill out this form and return it to us.)</p>
                  <ul>
                    <li>
                      <p>- To Web 3.0 Technologies Stiftung, Reiffergässli 4, 6300 Zug, Switzerland</p>
                    </li>
                    <li>
                      <p>- I/We (*) hereby cancel the contract that I/We (*) concluded to purchase the following goods
                        (*)/the following service (*)</p>
                    </li>
                    <li>
                      <p>- Ordered on (*)/received on (*)</p>
                    </li>
                    <li>
                      <p>- Name of the consumer(s)</p>
                    </li>
                    <li>
                      <p>- Address of the consumer(s)</p>
                    </li>
                    <li>
                      <p>- Signature(s) of the consumer(s) (only if notification is on paper)</p>
                    </li>
                    <li>
                      <p>- Date</p>
                    </li>
                  </ul>
                  <p>(*) Please delete as appropriate.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>8.</span> {" "}
                <h2>Final Provisions</h2>
              </article>
              <ol>
                <li>
                  <p>8.1</p>
                  <p>Agreements between Web3 and the Customers as well as their interpretation and enforcement are
                    subject to German laws excluding the applicability of the Convention on Contracts for the
                    International Sale of Goods (CISG).
                  </p>
                </li>
                <li>
                  <p>8.2</p>
                  <p>In case the customer is a merchant, a corporate body under public law or a special fund under
                    public law, the parties submit to the exclusive jurisdiction of the courts of Berlin, Germany.
                    Otherwise, the applicable statutory legal provisions shall apply with respect to the local and
                    international jurisdiction.</p>
                </li>
                <li>
                  <p>8.3</p>
                  <p>The European Commission provides a platform for an extrajudicial online dispute resolution. For
                    the purpose of settlement, disputes may be transmitted to the Online Dispute Resolution Platform
                    of the European Commission. The use of the website, which is administrated by the European
                    Commission, is free of charge. The platform is available to customers in case they have made an
                    online purchase and are residents in the EU. Web3 is neither obligated nor ready to join the
                    dispute resolution procedure.</p>
                </li>
                <li>
                  <p>8.4</p>
                  <p>If an individual provision is or becomes void or invalid, that provision will be modified to the
                    extent necessary to give effect to the commercial intentions of the parties and to make them
                    enforceable. This shall not affect the validity of the remaining provisions.</p>
                </li>
                <li>
                  <p>8.5</p>
                  <p>These GTC are drafted in both German and English. In case of discrepancies or contradictions
                    between the German and the English version, the German version shall prevail.</p>
                </li>
              </ol>
            </li>
          </ol>
        </section>
      </article>
      <article>
        <header>
          <h2>Allgemeine Geschäftsbedingungen der</h2>
          <article>
            <h3>Web 3.0 Technologies Stiftung</h3>
            <address>
              <p>Reiffergässli 4</p>
              <p>6300 Zug</p>
              <p>Switzerland</p>
            </address>
          </article>
        </header>
        <section>
          <header>
            <h2>- nachfolgend Web3 -</h2>
            <p>
              Web3, einer Stiftung schweizerischen Rechts mit Sitz in Zug, veranstaltet den Web3 Summit im Funkhaus
              Berlin, Deutschland (Web3 Summit), eine Veranstaltung zur Förderung des Austauschs und der
              Zusammenarbeit im Technologiebereich. Web3 verkauft die Tickets für die Teilnahme am Web3 Summit auf
              Grundlage der nachfolgenden Bedingungen.
            </p>
          </header>
          <ol>
            <li className="item">
              <article className="item__title">
                <span>1.</span>
                <h2>Geltungsbereich</h2>
              </article>
              <ol>
                <li>
                  <p>1.1</p>
                  <p>Sofern im Einzelfall nichts Abweichendes vereinbart wurde, gelten diese AGB für sämtliche
                    Verträge zwischen Web3 und ihren Kunden in Zusammenhang mit dem Erwerb von Tickets sowie der
                    Teilnahme am Web3 Summit.</p>
                </li>
                <li>
                  <p>1.2</p>
                  <p>
                    Mit dem Kauf von Tickets über die Webseite www.web3summit.com akzeptiert der Kunde (Kunde) die
                    nachfolgenden „Allgemeinen Geschäftsbedingungen (AGB).
                  </p>
                </li>
                <li>
                  <p>1.3</p>
                  <p>Der Kauf von Tickets für den Web3 Summit ist nur volljährigen sowie unbeschränkt geschäftsfähigen
                    natürlichen Personen sowie juristischen Personen gestattet.
                  </p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>2.</span>
                <h2>Recht zur Teilnahme an Veranstaltung</h2>
                <p>Mit Erwerb eines Tickets erhält der Kunde gegenüber Web3 ein Teilnahmerecht am Web3 Summit mit
                  folgendem Inhalt:</p>
              </article>
              <ol>
                <li>
                  <p>2.1</p>
                  <p>Die Tickets sind bis zwei (2) Tage vor Beginn des Web3 Summits (18.8.2019, 00.01 Uhr) frei
                    übertragbar.</p>
                </li>
                <li>
                  <p>2.2</p>
                  <p>Web3 kann das Programm einer Veranstaltung jederzeit ändern, insbesondere in Hinblick auf den
                    Programmablauf, soweit durch die Programmänderung das Wesen der Veranstaltung insgesamt nicht
                    verändert wird.</p>
                </li>
                <li>
                  <p>2.3</p>
                  <p>Auf die Haftung Web3 gegenüber den Teilnehmern am Web3 Summit finden die in Ziffer 5
                    beschriebenen Haftungsbeschränkungen Anwendung.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>3.</span>
                <h2>Vertragsschluss, Download der Tickets</h2>
              </article>
              <ol>
                <li>
                  <p>3.1</p>
                  <p>Tickets für den Web3 Summit verkauft Web3 auf der Webseite www.web3summit.com.</p>
                </li>
                <li>
                  <p>3.2</p>
                  <p>Mit der Bestellung von Tickets für den Web3 Summit beauftragt der Kunde Web3 mit der Abwicklung
                    des Kartenkaufs.</p>
                </li>
                <li>
                  <p>3.3</p>
                  <p>Ein verbindliches Angebot auf Abschluss eines Vertrages mit Web3 über den Erwerb von Tickets
                    erfolgt durch den Kunden, sofern dieser nach Eingabe aller Bestellinformationen den Button „Buy
                    (Kaufen)“ angeklickt hat. Nach Absendung der Bestellung erhält der Kunde eine automatische
                    Bestellbestätigung per E-Mail. Mit Zuteilung und Übersendung dieser Bestellbestätigung
                    einschließlich Bestellnummer durch Web3 bzw. den Dienstleister preTix kommt ein Vertrag zwischen
                    dem Kunden und Web3 über den Erwerb der bestellten Tickets zustande. Die Bestätigungs-E-Mail
                    enthält einen Link, über den der Kunde sein Ticket im PDF-Format aufrufen, downloaden und
                    abspeichern kann.

                  </p>
                </li>
                <li>
                  <p>3.4</p>
                  <p>Im Falle des Verlusts eines Tickets für den Web3 Summit und/oder der Bestätigungs-E-Mail können
                    Kunden die beim Kauf verwendete E-Mail-Adresse unter www.web3summit.com eingeben, um die
                    Bestätigungs-E-Mail einschließlich des Links zum Aufruf des Tickets erneut zu erhalten.</p>
                </li>
                <li>
                  <p>3.5</p>
                  <p>Umtausch und Rücknahme von Tickets sind grundsätzlich ausgeschlossen. Das Recht von Verbrauchern
                    zum Widerruf (Ziffer 6) bleibt unberührt.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>4.</span>
                <h2>Preise, Fälligkeit und Zahlung</h2>
              </article>
              <ol>
                <li>
                  <p>4.1</p>
                  <p>Die Preise für die Tickets des Web3 Summits sind über die Webseite der Veranstaltung unter
                    www.web3summit.com verfügbar. Der Gesamtpreis der bestellten Leistungen wird dem Kunden vor
                    Vertragsabschluss angezeigt.
                  </p>
                </li>
                <li>
                  <p>4.2</p>
                  <p>Sofern nichts hiervon Abweichendes vereinbart wurde, sind die Preise für Käufe der Kunden sofort
                    mit Vertragsschluss fällig.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>5.</span>
                <h2>Haftungsbeschränkung</h2>
              </article>
              <ol>
                <li>
                  <p>5.1</p>
                  <p>Eine Schadenersatzhaftung von Web3, ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen besteht
                    nur, wenn der Schaden</p>
                  <p>(a) auf Vorsatz oder grobe Fahrlässigkeit zurückzuführen ist; oder</p>
                  <p>(b) durch schuldhafte Verletzung einer vertragswesentlichen Pflicht (Kardinalpflicht), d.h. einer
                    Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht bzw. deren
                    Verletzung das Erreichen des Vertragszwecks gefährdet und auf deren Einhaltung der Vertragspartner
                    daher regelmäßig vertrauen darf, verursacht wurde.</p>
                </li>
                <li>
                  <p>5.2</p>
                  <p>Im Übrigen ist eine Haftung von Web3 auf Schadensersatz – gleich aus welchem Rechtsgrund –
                    ausgeschlossen.</p>
                </li>
                <li>
                  <p>5.3</p>
                  <p>Haftet Web3 gemäß Ziffer 5.1. (b) für die Verletzung einer vertragswesentlichen Pflicht, ohne
                    dass grobe Fahrlässigkeit oder Vorsatz vorliegen, so ist die Haftung gegenüber Unternehmern auf
                    denjenigen Schadensumfang begrenzt, mit dessen Entstehen Web3 bei Vertragsschluss aufgrund der ihr
                    zu diesem Zeitpunkt bekannten Umstände typischerweise rechnen musste.</p>
                </li>
                <li>
                  <p>5.4</p>
                  <p>Die vorstehenden Haftungsbeschränkungen bzw. -ausschlüsse gelten nicht bei der schuldhaften
                    Verletzung von Leben, Körper und Gesundheit, für Haftungsansprüche nach dem Produkthaftungsgesetz
                    sowie im Falle einer vertraglich vereinbarten verschuldensunabhängigen Einstandspflicht.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>6.</span>
                <h2>Widerrufsrecht</h2>
              </article>
              <ol>
                <li>
                  <p>6.1</p>
                  <p>Ein Widerrufsrecht für Verbraucher besteht nicht bei Verträgen zur Erbringung von
                    Dienstleistungen in den Bereichen Beherbergung zu anderen Zwecken als zu Wohnzwecken, Beförderung
                    von Waren, Kraftfahrzeugvermietung, Lieferung von Speisen und Getränken sowie zur Erbringung
                    weiterer Dienstleistungen im Zusammenhang mit Freizeitbetätigungen, wenn der Vertrag für die
                    Erbringung einen spezifischen Termin oder Zeitraum vorsieht (§ 312g Abs. 2 Satz 1 Nr. 9 BGB).</p>
                </li>
                <li>
                  <p>6.2</p>
                  <p>Für alle übrigen Verträge mit Verbrauchern gilt was folgt:</p>
                  <h3>Widerrufsbelehrung
                    Widerrufsrecht</h3>
                  <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter
                    Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
                  <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns,</p>
                  <article className="item__contact">
                    <h3>WTS Web 3.0 Technologies Services GmbH,</h3>
                    <address>
                      <span>Glogauer Straße 6, 10999 Berlin, </span>
                      <span>Germany</span>
                    </address>
                    <h3>Tel:</h3>
                    <span> +49 (0) 30 695 18100, </span>
                    <h3>E-mail: </h3>
                    <span>
                      <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
                    </span>
                  </article>
                  <p>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über
                    Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
                    Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
                  <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                    Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                  <h3>Folgen des Widerrufs</h3>
                  <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
                    haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus
                    ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste
                    Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
                    zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
                    Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
                    Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
                    in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
                  <h3>Muster-Widerrufsformular</h3>
                  <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie
                    es zurück.)</p>
                  <ul>
                    <li>
                      <p>- An Web 3.0 Technologies Stiftung, Reiffergässli 4, 6300 Zug, Schweiz</p>
                    </li>
                    <li>
                      <p>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf
                        der folgenden Waren (*)/die Erbringung der – —- folgenden Dienstleistung (*)</p>
                    </li>
                    <li>
                      <p>- Bestellt am (*)/erhalten am (*)</p>
                    </li>
                    <li>
                      <p>- Name des/der Verbraucher(s)
                      </p>
                    </li>
                    <li>
                      <p>- Anschrift des/der Verbraucher(s)</p>
                    </li>
                    <li>
                      <p>- Unterschrift/en des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
                    </li>
                    <li>
                      <p>- Datum</p>
                    </li>
                  </ul>
                  <p>(*) Unzutreffendes streichen.</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>8.</span>
                <h2>Schlussbestimmungen</h2>
              </article>
              <ol>
                <li>
                  <p>8.1</p>
                  <p>Verträge zwischen Web3 und den Kunden sowie deren Auslegung und Durchführung unterliegen
                    deutschem Recht unter Ausschluss des Übereinkommens über Verträge über den internationalen
                    Warenkauf (CISG).</p>
                </li>
                <li>
                  <p>8.2</p>
                  <p>Sofern es sich bei dem Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts
                    oder ein öffentlich-rechtliches Sondervermögen handelt, vereinbaren die Parteien Berlin,
                    Deutschland, als ausschließlichen Gerichtsstand. Im Übrigen gelten für die örtliche und
                    internationale Zuständigkeit die anwendbaren gesetzlichen Bestimmungen.</p>
                </li>
                <li>
                  <p>8.3</p>
                  <p>Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung
                    zur Verfügung. Zur Beilegung können Streitigkeiten an die Online-Schlichtungsplattform der
                    Europäischen Kommission übermittelt werden. Die Nutzung der Seite, die von der Europäischen
                    Kommission verwaltet wird, ist kostenfrei. Die Plattform steht den Kunden zur Verfügung, wenn Sie
                    einen Online-Kauf getätigt haben und innerhalb der EU ansässig sind. Web3 ist weder verpflichtet
                    noch bereit, an dem Streitschlichtungsverfahren teilzunehmen.</p>
                </li>
                <li>
                  <p>8.4</p>
                  <p>Sollte eine Bestimmung unwirksam oder nichtig sein oder werden, so wird die unwirksame oder
                    nichtige Bestimmung so weit geändert, wie dies notwendig ist, um die wirtschaftlichen Absichten
                    der Parteien wirksam und durchsetzbar werden zu lassen. Die Wirksamkeit der übrigen Vereinbarung
                    wird hiervon nicht berührt.</p>
                </li>
                <li>
                  <p>8.5</p>
                  <p>Diese AGB sind in deutscher und englischer Sprache ausgefertigt. Im Fall einer Unstimmigkeit oder
                    eines Widerspruchs zwischen der deutschen und der englischen Fassung hat die deutsche Fassung
                    Vorrang.</p>
                </li>
              </ol>
            </li>
          </ol>
        </section>
      </article>
    </Layout >
  )
}


export default TermsAndConditions
