import * as React from "react"
import Layout from "../../components/Layout"

const ContactPage = () => {
  return (
    <Layout page={"contact"} title="Contact" withAnimation>
      <h2>Contact</h2>
      <p>
        For general inquiries: {" "}
        <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
      </p>
      <p>
        For press inquiries: {" "}
        <a href="mailto:press@web3summit.com">press@web3summit.com</a>
      </p>
      <p>
        For updates follow {" "}
        <a href="https://twitter.com/web3foundation">@web3foundation</a>
        {" "} on Twitter or subscribe to {" "}
        <a href="https://www.reddit.com/r/web3/">r/web3</a>
      </p>
    </Layout>
  )
}

export default ContactPage
