import * as React from "react";
import { videos } from "../../content/json/videos/videos.json";

const VideoColumn = ({ category }) => {
  return (
    <section>
      <h2>{category}</h2>

      {videos.map((video) => {
        return (
          video.category === category && (
            <article key={video.title}>
              <a target="_blanc nofollow noreffer" href={video.link}>
                <h3>{video.title}</h3>
              </a>
              <p>{video.speakers}</p>
            </article>
          )
        );
      })}
    </section>
  );
};

export default VideoColumn;
