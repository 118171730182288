import * as React from "react"
import ProgramSpeaker from "./ProgramSpeaker"

const ProgramItem = ({ item }) => {
  const { start, end, type, title, speakers } = item;
  return (
    <article className="program__item">
      <time dateTime={``}>{start} {end && `- ${end}`}</time> {" "}
      {type && <span>{"//"} {type}</span>}
      <h4>{title}</h4>
      {
        speakers && speakers.map((speaker, i) => (
          <ProgramSpeaker key={i} speaker={speaker}></ProgramSpeaker>
        ))
      }
    </article>

  )
}

export default ProgramItem
