import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import Gallery from "../../../components/Gallery"

const PhotoPageStudio41 = ({ data }) => {
  return (
    <Layout title="Studio 4.1">
     <Gallery photos={data} />
    </Layout>
  )
}

export default PhotoPageStudio41

export const query = graphql`
query studio41 {
  allMdx(
    filter: {slug: {regex: "/studio-4-1/"}}
    sort: {fields: frontmatter___title, order: ASC}
    ){
    nodes {
      frontmatter {
        photos {
          childImageSharp {
            gatsbyImageData
          }
        }
        studio
        title
      }
    }
  }
}
`
