import * as React from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => {
  return (
    <Layout title="About" page={"about"} withAnimation>
      <article>
        <h2>About</h2>
        <p>
          <strong>Web3 Summit is organized around a single rallying call: to facilitate a fully functional and user-friendly decentralized web.</strong>
        </p>
        <p>
          We invite devs and researchers working on lower level protocols and others interested in the latest developments in the decentralised web to join us in Berlin for an immersive and collaborative gathering focusing on: P2P protocols. Platform neutral computation language. Data distribution protocols. Blockchains. Transient data/messaging. Encrypted storage. Protocol-extensible developer APIs.
        </p>
        <p>
          Web3 Summit will have space available for workshops, presentations, AMA sessions – both intimate and large-scale, chill-out rooms, and most importantly, a large hackerspace, where you can host a hackathon, whiteboard some ideas, present to other teams, and get real work done.
        </p>
      </article>
      <StaticImage
        alt=""
        src="../../../content/images/package.jpeg"
      />
      <aside>
        <h3>About our venue</h3>
        <p>
          <a href="https://www.funkhaus-berlin.net/">Funkhaus</a>
          {" "}originally functioned as the main radio production and broadcasting facility for the German Democratic Republic. A central front in the culture-wars between East and West, the GDR spared no expense in designing and building out what were at the time, state-of-the-art recording and performance spaces.
        </p>
      </aside>
    </Layout>
  )
}

export default AboutPage

