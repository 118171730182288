import * as React from "react"
import Layout from "../../components/Layout"

const GetInvolvedPage = () => {
  return (
    <Layout page={"get-involved"} title="Get Involved" withAnimation>
      <article>
        <h2>Get Involved:</h2>
        <p>Web3 Summit is an open source gathering built, modified and enhanced by you.</p>
        <p>Web3 Foundation will provide the infrastructure for you to create your own experience. Of course we’ll make sure there are plenty of great speakers. But we all know the best part about bringing together 1,000+ devs, researchers and interested parties, is the collaborative ideas and outcomes brought about by IRL proximity.</p>
        <p>Want to organize a workshop? Present some new research you’ve been working on? Host a clandestine dance party? We invite you to make Web3 Summit your home for 3 days.</p>
      </article>
      <article>
        <h3>Volunteer:</h3>
        <p>Our volunteers are our VIPs. Come lend us your unique skills (or just some of them for a small commitment of time) – we will be forever grateful (and you’ll enjoy free entry, food and drink on the day of assignment and a free Web3 Summit t-shirt!)</p>
        <p>Need more info? Email us on {" "}
          <a href="mailto:volunteer@web3summit.com">
          volunteer@web3summit.com.
          </a>
          {" "}Looking forward to having you on board!</p>
      </article>
      <article>
        <h3>Hackerspace:</h3>
        <p>The Hackerspace will lay the foundation for community and creative expression at Web3 Summit. It will be a unique format: self-organized and participant-driven. This vibrant hub will be divided into seven individual camps (appropriately referred to as “Nodes”) that will enable exploration and foster communication between different teams working on similar initiatives and protocols. Share your research, code and ideas. Hang out. Drink Club Mate. Host people. Make friends. Do great things. Let’s co-create the space!</p>
        <p>Questions? Please reach out to {" "}
          <a href="mailto:hackerspace@web3summit.com">
          hackerspace@web3summit.com
          </a>
          </p>
      </article>
      <article>
        <h3>Workshops:</h3>
        <p>Want to dive deep into a particular problem? We’ll happily provide you with a room to host a 1-3 hour workshop, where you can either organise a technical tutorial or a discussion session that would lead to tangible outcomes.</p>
        <p>Questions? Please reach out to {" "}
          <a href="mailto:workshop@web3summit.com">
          workshop@web3summit.com
          </a>
          </p>
      </article>
      <article>
        <h3>Chat and Things:</h3>
        <p>Connect with other Web3 Summit community organizers through our {" "}
          <a href="https://riot.im/app/#/room/#web3summit:matrix.org">
          Riot Channel
          </a>
          {" "}PUBLIC COLLAB: Web3 Summit: Berlin Oct 22-24” and follow {" "}
          <a href="https://twitter.com/web3foundation">
          @web3foundation
          </a>
          {" "} on Twitter and subscribe to {" "}
          <a href="https://www.reddit.com/r/web3/">
          r/web3
          </a>
          {" "} for updates</p>
      </article>
    </Layout>
  )
}

export default GetInvolvedPage
