import * as React from 'react'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Node = ({ data }) => {

  return (
    <Layout page={"node"} title={data.mdx.frontmatter.title} withAnimation>
      <h2>{data.mdx.frontmatter.title}</h2>
      <p>{data.mdx.frontmatter.hosts}</p>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
  mdx(id: {eq: $id}, frontmatter: {type: {eq: "hackerspace"}}) {
    frontmatter {
     title
     hosts
    }
    id
    body
  }
}
`


export default Node
