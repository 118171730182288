import * as React from 'react'
import { Helmet } from 'react-helmet'
import Animation from '../components/Animation'

const HomePage = () => {
  return (
    <div className="homepage site-with-animation">
      <Helmet>
        <title>Web3 Summit 2018</title>
      </Helmet>
      <Animation homeAnimation />
      <Animation notAnimated middle />
      <Animation notAnimated reverse />
    </div>
  )
}

export default HomePage
