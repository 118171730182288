import * as React from 'react'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Speaker = ({ data }) => {

  return (
    <Layout title={data.mdx.frontmatter.speaker} className="ind-speaker" withAnimation>
      <h2 className="ind-speaker__name">{data.mdx.frontmatter.speaker}</h2>
      <article className="ind-speaker__info">
        <span className="ind-speaker__position">{data.mdx.frontmatter.position}</span>
        <span className="ind-speaker__company"> - {data.mdx.frontmatter.company}</span>
      </article>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}, frontmatter: {type: {eq: "speaker"}}) {
      frontmatter {
        speaker
        company
        position
      }
      id
      body
    }
  }
`

export default Speaker
