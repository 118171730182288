import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import Gallery from "../../../components/Gallery"

const PhotoPageStudio42 = ({data}) => {
  return (
    <Layout title="Studio 4.2">
      <Gallery photos={data}/>
    </Layout>
  )
}

export default PhotoPageStudio42

export const query = graphql`
query studio42 {
  allMdx(
    filter: {slug: {regex: "/studio-4-2/"}}
    sort: {fields: frontmatter___title, order: ASC}
    ){
    nodes {
      frontmatter {
        photos {
          childImageSharp {
            gatsbyImageData
          }
        }
        studio
        title
      }
    }
  }
}
`
