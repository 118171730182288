import React from "react"
import Layout from "../../components/Layout"
import SiteNavigation from "../../paths.json"
import NavBar from "../../components/NavBar";

const PrivacyPolicy = () => {
  return (
    <Layout page="privacy" title="Privacy Policy" withAnimation>
      <NavBar subNav paths={SiteNavigation.imprint} />
      <article>
        <header>
          <p>Web 3.0 Technologies Stiftung, together with other members of its group (we or us) are committed to
            compliance with data protection laws and this policy (Privacy Policy) sets out our personal information
            gathering and sharing practices for www.web3summit.com (the Website).</p>
          <p>This Website may contain links to other third party websites. If you follow a link to any of those third
            party websites, please note that they have their own privacy policies and that we do not accept any
            responsibility or liability for their policies or processing of your personal information. Please check
            these policies before you submit any personal information to such third party websites.</p>
          <p>This Privacy Policy is intended to explain our privacy practices and covers the following areas</p>
          <ol className="privacy__contents">
            <li>
              <p>1</p>
              <p>What personal information about you we may collect</p>
            </li>
            <li>
              <p>2</p>
              <p>How we may use your personal information</p>
            </li>
            <li>
              <p>3</p>
              <p>Who we may disclose your personal information to</p>
            </li>
            <li>
              <p>4</p>
              <p>How we protect your personal information</p>
            </li>
            <li>
              <p>5</p>
              <p>How we may export your personal information outside of the EEA</p>
            </li>
            <li>
              <p>6</p>
              <p>How long we may store your personal information</p>
            </li>
            <li>
              <p>7</p>
              <p>Contacting us & your rights to prevent marketing and to access and update your personal information</p>
            </li>
            <li>
              <p>8</p>
              <p>How changes to this Privacy Policy will be made</p>
            </li>
          </ol>
        </header>
        <section>
          <ol>
            <li className="item">
              <article className="item__title">
                <span>1 </span>
                <h2>Information we may collect about you</h2>
              </article>
              <p>We may collect and process the following personal information about you: </p>
              <ol>
                <li>
                  <p>(a) Information you provide to us: personal information that you provide to us, such as during the
                    registration process to access and use the Website including your name, address, email, contact
                    phone number, name and/or address of related entity (if sponsored by an entity), information on
                    professional background such as CV, brief bio, demos, your bank account details;</p>
                </li>
                <li>
                  <p>(b) Our correspondence: if you contact us, we may keep a record of that correspondence;</p>
                </li>
                <li>
                  <p>(c) Your transactions: details of transactions you carry out through our Website and of the
                    fulfilment of the services we provide;</p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>2 </span>
                <h2>Uses made of your personal information and justification of uses</h2>
              </article>
              <ol>
                <li>
                  <p>We may use your personal information in the following ways.Use of personal information under EU
                    data protection laws must be justified under one of a number of legal “grounds” and we are
                    required to set out the grounds in respect of each use in this policy.For each use, we note the
                    grounds we use to justify each use of your personal information: </p>
                </li>
                <li>
                  <p>a) To register you as a user: You will create an account by providing the relevant information as specified below:</p>
                  <p>- Registration for:</p>
                  <p>- Personal data you may provide:</p>
                  <p>If required for hosting or organising the Web3 Summit or the events or activities you registered for, we may contact you by using the contact data you provided to us.
                    Use justification: consent, contract performance, legitimate interests (to allow us to on-board you as a user);
                  </p>
                </li>
                <li>
                  <p>(b) To conclude and perform a contract when you buy a ticket on our Website: to administer our
                    services, including to carry out our obligations arising from the contracts entered into between
                    you and us, to receive payments from you.This includes the sale of tickets for events hosted by
                    or together with us.In case you purchase tickets for third parties, we may process those third
                    parties’ personal information provided by you for ticket personalization.In this case, you need
                    to make sure that the third parties are sufficiently informed about the processing of their data
                    by us and that you are entitled to provide those data. Use justification: consent, contract
                    performance, legitimate interests (to enable us to perform our obligations and provide services to
                    you); </p>
                </li>
                <li>
                  <p>(c) To inform you about our services: We may use your information for informing you about our
                    services e.g.informing you as a participant of an event to view our open job listings for
                    researchers by email or post and, where required by law, we will ask for your consent at the time
                    we collect your data to inform you about our services.Use justification: legitimate interests (to
                    allow us to inform you about our services); </p>
                </li>
                <li>
                  <p>(d) To ensure our Website functions correctly: to ensure that content from our Website is presented
                    in the most effective manner for you and for your computer.Use justification: contract performance,
                    legitimate interests (to allow us to provide you with the content and services on the Website); </p>
                </li>
                <li>
                  <p>(e) For research and development purposes: to analyse, develop and continuously improve our
                    products, services and systems and to adapt them to our users’ needs.In doing so, we may use the
                    information you provide us during the ticket purchase process in order to invite you to surveys.
                    Use justification: legitimate interests (to allow us to improve our services); </p>
                </li>
                <li>
                  <p>(f) To inform you of changes: to notify you about changes to our service, including updates or
                    changes to the logistics or programming of the events we host.Use justification: contract
                    performance, legitimate interests (to allow us to perform or continuously develop our services); </p>
                </li>
                <li>
                  <p>(g) To carry out legal disputes: to enforce and/or defend our rights.To the extent necessary for
                    executing a legal dispute, we may also make use of data from other sources (e.g.public
                    registers).Use justification: contract performance, legitimate interests (to represent, enforce and
                    / or defend our legal interests); </p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>3 </span>
                <h2>Disclosures to third parties and justification of uses</h2>
              </article>
              <ol>
                <li>
                  <p>3.1</p>
                  <p>To allow us to effectively provide our services to you, we may disclose your information to
                    selected third parties: We may permit selected third parties such as business partners, suppliers,
                    service providers, agents and contractors to use your personal information, for the purposes set
                    out in paragraph 2 above, who will be subject to obligations to process such information in
                    compliance with the same safeguards that we deploy.Currently, we disclose your personal
                    information</p>
                  <ul>
                    <li>
                      <p>– to our parent company Web 3.0 Technologies Stiftung as a service provider for the purpose of supporting the organisation of the Web3 Summit or other events;</p>
                    </li>
                    <li>
                      <p>- to the Rocket Science Group LLC[MailChimp]for the purposes of IT operations, mainly the
                        provision of mailing services; </p>
                    </li>
                    <li>
                      <p>- to Raphael Michel rami.io Softwareentwicklung [pretix] for the purposes of handling ticket
                        sales and payment reconciliation; </p>
                    </li>
                    <li>
                      <p>- depending on the payment option you chose, to Stripe, banks and/or credit card companies, for
                        the purpose of payment processing; </p>
                    </li>
                    <li>
                      <p>- to the competent courts or to appointed lawyers, to carry out a legal dispute.
                        Use justification: contract performance, legitimate interests (to enable us to effectively
                        provide our services to you); </p>
                    </li>
                  </ul>
                  <p>Use justification: contract performance, legitimate interests (to enable us to effectively provide our services to you);</p>
                </li>
                <li>
                  <p>3.2</p>
                  <p>To carry out legal disputes: we may transfer your personal information to the competent courts
                    and appointed lawyers. Use justification: contract performance, legitimate interests (to represent,
                    enforce and / or defend our legal interests); </p>
                </li>
                <li>
                  <p>3.3</p>
                  <p>To comply with our legal obligations: We may disclose your personal information to third parties
                    insofar legal obligations exist on our part to pass it on, e.g.to the police authorities in the
                    context of criminal investigations or to the data protection supervisory authorities.
                    Use justification: legal obligation, legitimate interests (to cooperate with law enforcement and
                    regulatory authorities); </p>
                </li>
                <li>
                  <p>3.4</p>
                  <p>To transfer your information when the structure of the business changes: In the event that we (or
                    a part thereof) are (i) subject to negotiations for the sale of our business or (ii) is sold to a
                    third party or (iii) undergo a reorganisation, any of your personal information which we hold may
                    be transferred to that re-organised entity or third party and used for the same purposes as set
                    out in this policy, or for the purpose of analysing any proposed sale or re-organisation.We will
                    ensure that no more of your information is transferred than necessary.Use justification: legitimate
                    interests (to allow us to change our business); </p>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>4 </span>
                <h2>Security of your personal information</h2>
              </article>
              <p>No data transmission over the Internet or Website can be guaranteed to be secure from intrusion.
                However, we maintain commercially reasonable physical, electronic and procedural safeguards to protect
                your personal information in accordance with data protection legislative requirements.</p>
            </li>
            <li className="item">
              <article className="item__title">
                <span>5 </span>
                <h2>Export of your personal information outside the EEA</h2>
              </article>
              <ol>
                <li>
                  <p>5.1</p>
                  <p>Your personal information may be accessed by staff or suppliers in, transferred to, and/or stored
                    at, a destination outside the European Economic Area (EEA) in which data protection laws may be of
                    a lower standard than in the EEA. Regardless of location or whether the person is an employee or
                    contractor we will impose the same data protection safeguards that we deploy inside the EEA.
                  </p>
                </li>
                <li>
                  <p>5.2</p>
                  <p>Certain countries outside the EEA have been approved by the European Commission as providing
                    essentially equivalent protections to EEA data protection laws and therefore no additional
                    safeguards are required to export personal information to these jurisdictions . In countries which
                    have not had these approvals, (see the full list here
                    <a href="http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm">
                      http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm)
                    </a>
                    , we
                    will transfer your personal information subject to European Commission approved contractual terms
                    that impose equivalent data protection obligations directly on the recipient.</p>
                </li>
                <li>
                  <p>5.3</p>
                  <p>Where we use the service provider Rocket Science Group LLC [MailChimp], your personal information
                    will be transferred to the USA, which have not been approved by the EU Commission to provide an
                    essentially equivalent level of data protection. As Rocket Science Group LLC is subject to the
                    EU-US Privacy Shield., this safeguards an adequate protection of your personal data. The complete
                    text of the EU-US Privacy Shield Framework can be found under the following link:</p>
                  <a href="https://www.privacyshield.gov/servlet/servlet.FileDownload?file=015t00000004qAg">
                    https://www.privacyshield.gov/servlet/servlet.FileDownload?file=015t00000004qAg
                  </a>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>6 </span>
                <h2>Storage limits</h2>
              </article>
              <ol>
                <li>
                  <p>6.1</p>
                  <p>We will retain your personal information for as long as is necessary for the processing
                    purpose(s) for which it was collected and any other permitted linked purpose (for example certain
                    transaction details and correspondence may be retained until the time limit for claims in respect
                    of the transaction has expired or in order to comply with regulatory requirements regarding the
                    retention of such data). So if information is used for two purposes we will retain it until the
                    purpose with the latest period expires; but we will stop using it for the purpose with a shorter
                    period one that period expires.</p>
                </li>
                <li>
                  <p>6.2</p>
                  <p>We restrict access to your personal information to those persons who need to use it for the
                    relevant purpose(s). Our retention periods are based on business needs and your information that
                    is no longer needed is either irreversibly anonymised (and the anonymised information may be
                    retained) or securely destroyed. By way of example:</p>
                  <ol>
                    <li>
                      <p>(a) use to perform a contract: in relation to your personal information used to perform any
                        contractual obligation with you, we may retain that personal information whilst the contract
                        remains in force plus a further 6 years to deal with any queries or claims thereafter; and</p>
                    </li>
                    <li>
                      <p>(b) where claims are contemplated: in relation to any information where we reasonably believe
                        it will be necessary to defend or prosecute or make a claim against you, us or a third party, we
                        may retain that information for as long as that claim could be pursued.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>7 </span>
                <h2>Your rights & contacting us</h2>
              </article>
              <ol>
                <li>
                  <p>7.1</p>
                  <h3>Your rights</h3>
                  <p>If you have any questions in relation to our use of your personal information, you should first
                    contact us as per paragraph 7.4 below.</p>
                  <br />
                  <p>Under certain conditions, you may have the right to require
                    us to:</p>
                  <ul>
                    <li>
                      <p>(a) provide you with further details on the use we make of your information;</p>
                    </li>
                    <br />
                    <li>
                      <p>(b) be provided with access to any data held about you by us, generally within 1 month of your
                        request;</p>
                    </li>
                    <br />
                    <li>
                      <p>(c) update any inaccuracies in the personal information we hold (please see paragraph 5.3
                        below);</p>
                    </li>
                    <br />
                    <li>
                      <p>(d) You can ask us to erase Personal Data in certain circumstances and we will take reasonable
                        steps to inform other controllers that are processing the data that you have requested the
                        erasure of any links to, copies or replication of it.
                      </p>
                    </li>
                    <br />
                    <li>
                      <p>(e) object to any processing based on the legitimate interests ground unless our reasons for
                        undertaking that processing outweigh any prejudice to your data protection rights (please see
                        paragraph 7.4 below); and</p>
                    </li>
                    <br />
                    <li>
                      <p>(f) restrict how we use your information whilst complaints are resolved and also restrict
                        processing in certain other circumstances.</p>
                    </li>
                  </ul>
                  <p>Your exercise of these rights is subject to certain exemptions to safeguard the public interest
                    (e.g. the prevention or detection of crime) and our interests (e.g. the maintenance of legal
                    privilege). If you exercise any of these rights we will check your entitlement and respond in most
                    cases within a month. If you are not satisfied with our use of your personal information or our
                    response to any exercise of these rights you have the right to complain to your data protection
                    authority.</p>
                </li>
                <li>
                  <p>If we process your personal information on the basis of legitimate interests (use justification),
                    you have the right to object to the data processing at any time. Unless we demonstrate overriding
                    compelling legitimate grounds for the processing of the personal information within the meaning of
                    Art. 21 GDPR, we will comply with your objection. Please contact us as set out in paragraph 7.4
                    below If you object to the data processing according to this paragraph 7.2, we process your
                    personal information collected in this context to answer your inquiry. Your personal data will
                    then be processes in order to fulfil our legal obligations.</p>
                </li>
                <li>
                  <h3>Data Controller</h3>
                  <p>WTS Web 3.0 Technologies Services GmbH is responsible for and is the “data controller” of your personal
                    information processed by us under this Privacy Policy.</p>
                </li>
                <li>
                  <p>We can be contacted in relation to your rights or any questions you may have in respect of this
                    Privacy Policy or our processing of your personal information at the following addresses:</p>
                  <article className="item__contact">
                    <address>
                      <span>By email: </span>
                      <a href="mailto:contact@web3summit.com">contact@web3summit.com</a>
                    </address>
                    <address>
                      <span>By post: </span>
                      <span>Reiffergässli 4, 6300 Zug, Schweiz</span>
                    </address>
                  </article>
                </li>
              </ol>
            </li>
            <li className="item">
              <article className="item__title">
                <span>8 </span>
                <h2>Changes to our Privacy Policy</h2>
              </article>
              <p>We may change the content of our Website or services without notice, and consequently our Privacy
                Policy may change at any time in the future. We therefore encourage you to review it from time to time
                to stay informed of how we are using personal information.</p>
            </li>
          </ol>
        </section>
      </article>

    </Layout>
  )
}

export default PrivacyPolicy
