import * as React from "react"
import ProgramItem from "./ProgramItem";

const ProgramColumn = ({ data, day }) => {

  return (
    <section className="program__column">
      <h2>{day}</h2>
      {
        data.map((program, i) => (
          <section className="program__row" key={`${day}${i}`}>
            {program.topic && <h3>{program.topic}</h3>}
            {program.room && <h3 className="program__room">ROOM {program.room}</h3>}
            {
              program.items.map((item, i) => (
                <ProgramItem key={`${item}${i}`} item={item}></ProgramItem>
              ))
            }
          </section>
        )
        )
      }

    </section>
  )
}

export default ProgramColumn
