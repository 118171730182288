import * as React from "react"
import Layout from "../../../components/Layout"
import ProgramColumn from "../../../components/ProgramColumn"
import Container from "../../../components/Container"
import ProgramData from "../../../../content/json/program/program-workshop.json"

const ProgramPageWorkshop = () => {

  return (

    <Layout title="Studio 4.0 - Workshops & Talks">
      <Container page={"program"}>
        {ProgramData.program.map((program, i) => {
          return (
            <ProgramColumn key={`${program.stage}${i}`} data={program.categories} day={program.day} />
          )
        })}
      </Container>
    </Layout>
  )
}

export default ProgramPageWorkshop

